<template>
  <div class="flex flex-col gap-2">
    <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
      <TabInsights
        :title="`Average Treatment By Gender And Age (${treatmentType.toUpperCase()})`"
        toolTiptext="What can I learn from this chart?"
        :hasQuickInsights="false"
      >
        <AverageTreatmentByGenderAndAge
          :type="treatmentType"
          :selected="selected"
          :chartDataProps="chartData"
          @updateChartData="chartData = $event"
        />
      </TabInsights>
    </vs-row>

    <!-- <div
      class="flex mt-2" style="justify-content: flex-end;">
      <vs-button style="padding:5px 15px;" @click="handleExportToCSV">
        Export
      </vs-button>
    </div> -->
    <vs-table
      v-if="filteredData && filteredData.length"
      max-items="10"
      pagination
      noDataText="No Data Available"
      :data="filteredData"
    >
      <template slot="thead">
        <vs-th
          style="
            background-color: rgba(235, 247, 244, 1);
            color: #5e5873;
            width: 30vw;
            max-width: 30vw;
          "
          >AGE BRACKET</vs-th
        >
        <vs-th style="background-color: rgba(235, 247, 244, 1); color: #5e5873"
          >FEMALE</vs-th
        >
        <vs-th style="background-color: rgba(235, 247, 244, 1); color: #5e5873"
          >MALE</vs-th
        >
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.ageBracket">
            {{ tr.ageBracket }}
          </vs-td>
          <vs-td :data="tr.Female">
            {{ tr.Female ? tr.Female : '0.00' }}
          </vs-td>
          <vs-td :data="tr.Male">
            {{ tr.Male ? tr.Male : '0.00' }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AverageTreatmentByGenderAndAge from "../../components/insights/treatments/AverageTreatmentByGenderAndAge.vue";
import TabInsights from "../../components/insights/TabsInsights.vue";
import Sidebar from "./Sidebar.vue";
import utils from '@/assets/utils'
import moment from 'moment';

export default {
  components: {
    AverageTreatmentByGenderAndAge,
    TabInsights,
    Sidebar,
  },
  data() {
    return {
      selected: {
        postcode: [],
        suburb: [],
        brand: [],
        product: [],
        clinic: [],
        org: [],
        state: [],
        nurse: [],
        frequency: 30,
      },
      chartData: {},
      filteredData: {},
      treatmentType: "",
      urlType: "",
      orgId: "",
    };
  },
  methods: {
    ...mapActions("analytics", ["fetchTreatmentAreasAgeGender"]),
    getTreatmentAreasAgeGender() {
      this.$vs.loading();
      let payload = {
        treatment_product_id: this.selected.product,
        postcode: this.selected.postcode,
        frequency: this.selected.frequency,
        brand_id: this.selected.brand,
        state_abbreviation: this.selected.state,
        suburb_name: this.selected.suburb,
        clinic_id: this.selected.clinic,
        type: this.treatmentType,
        org_id: this.selected.org,
      }
      if(!["SuperAdmin", "Supplier"].includes(this.urlType)){
        payload = {
          ...payload,
          org_id: [this.orgId],
          nurse_id: this.selected.nurse
        }
      }
      this.fetchTreatmentAreasAgeGender(payload)
        .then((res) => {
          this.chartData = res.data.chartData;
          const { labels, datasets } = res.data.chartData;
          const brands = datasets.map((brand) => brand.label)
          this.filteredData = labels.map((label, i) => {
            let data = { ageBracket: label }
            brands.map((_, ii) => {
              data = {
                ...data,
                [datasets[ii].label]: datasets[ii].data[i]
              }
            })
            return data;
          })
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.error("#ERR :", err.message);
          this.$vs.loading.close();
        });
    },
    handleExportToCSV() {
      let csv = "\ufeff";
      let filename = `avg_dose(mL)_per_treatment_by_gender_and_age(${this.treatmentType})_`
      csv += "AGE BRACKET, FEMALE, MALE\n";

      this.filteredData.forEach(row => {
        csv += `${row},${row.Female ? row.Female : '0.00'},${row.Male ? row.Male : '0.00'}\n`;
      });

      utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
    }
  },
  watch: {
    $route:  {
      handler(newVal){
        if([
          "SuperAdminAverageTreatmentByGenderAndAgeFiller", 
          "OrgOwnerAverageTreatmentByGenderAndAgeFiller",
          "SupplierAverageTreatmentByGenderAndAgeFiller"
        ].includes(newVal.name)){
          this.treatmentType = "filler";
        }else {
          this.treatmentType = "toxin";
        }
        setTimeout(() => {
          this.getTreatmentAreasAgeGender();
        }, 250);
      },
      immediate: true,
    },
    selected: {
      handler() {
        this.getTreatmentAreasAgeGender();
      },
      deep: true,
    },
  },
  async created() {
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    if(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)){
      this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
    }
    if(userType && userType.brands.length){
      this.selected.brand = userType.brands;
    }
    // this.getTreatmentAreasAgeGender();
  },
};
</script>
