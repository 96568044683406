

<template>
  <vs-row vs-w="12">
      <Filters
        :selected="selected"
        :page="`average-treatment-by-gender-and-age-${type}`"
        @updateFilters="selected = $event"
      />
      <!-- Chart -->
      <vs-col vs-w="12">
        <BarChart 
          :chartData="chartData"
          :chartOptions="chartOptions"
          :height="150"
        />
      </vs-col>
    </vs-row>
</template>

<script>
import BarChart from '../charts/BarChart.vue'
import Filters from "../common/Filters.vue"

export default {
  components: {
    BarChart,
    Filters
  },
  props: {
    tabs: Array,
    type: {
      type: String,
    },
    selected: {
      type: Object,
    },
    chartDataProps: {
      type: Object,
    },
  },
  data() {
    return {
      chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            labels: {
              boxWidth: 12
            }
          },
          title: {
            display: true,
            text: ''
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'AVERAGE TREATMENT GENDER'
            },
          },
          x: {
            title: {
              display: true,
              text: 'AGE BRACKET'
            },
            grid : {
                display : false
            },
          }
        }   
      }
    }
  },
  watch: {
    chartDataProps: {
      handler(newVal){
        this.chartData = newVal;
      },
      immediate: true,
    },
    chartData: {
      handler(newVal) {
        this.$emit("updateChartData", newVal);
      },
      deep: true,
    }
  }
}
</script>